import { NgModule } from '@angular/core';
import { KnobComponent } from './knob.component';

@NgModule({
  imports: [
  ],
  declarations: [KnobComponent],
  exports: [KnobComponent]
})
export class KnobModule { }
